import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Microbiologie() {

	return (
		<>
			<PageTitle>MICROBIOLOGY</PageTitle>

			<PageVideo videoName={"itwLenogue"} style={{ paddingTop: '40px' }}>
				Delphine lenogue<br />MICROBIOLOGY LABORATORY MANAGER
			</PageVideo>

			<PageText style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				<p>
					Our teams of expert microbiologists work on a daily basis to ensure that <span className="bold">our products are preserved for as long as consumers use them</span>, in full respect of skin balance.
					<br />
					<br />
					The department is made up of <span className="bold">three teams working at different stages of the development process</span>.
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Microbiologie/microbiologie1.png"} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				<p className="paddingTopBottom">
					Upstream of formula development, a dedicated research team sets up a dozen collaborations every year, <span className="bold">to gain a better understanding of skin microorganisms and their environment</span>.
				</p>
				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="paddingTopBottom">
						<p>
							<span className="bold">This innovative work</span> is regularly shared in peer-reviewed scientific journals and at international conferences.
						</p>
					</div>
				</div>
				<p className="paddingTopBottom">
					We also <span className="bold">develop mixtures of preservatives</span> and molecules with a preservative effect, <span className="bold">striking a subtle balance between antimicrobial efficacy and good skin tolerance</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Microbiologie/microbiologie2.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p style={{ color: "white" }} >
					The two other microbiology are dedicated to :
					<br /><br />
					• <span className="bold">assessing the effectiveness of preservative systems</span> in the formulas we develop, and to carrying out
					<br /><br />
					• <span className="bold">microbiological controls of the industrial environment, raw materials and finished products</span> before they are put on the market.
					<br /><br />
					<span className="bold">Security and quality are the keywords respected in all our activities</span>.
				</p>
			</PageDisplayImage>
		</>
	)
}
