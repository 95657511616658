import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function PoleEvaluation() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	return (
		<>
			<PageTitle>SEGURIDAD Y <br />VIGILANCIA</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Securite/securite1.png"} backgroundImage="none" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					EVALUACIÓN DE LA SEGURIDAD
				</h1>
				<p className="paddingTop">
					El polo de evaluación de la seguridad y vigilancia realiza la <span className="bold">evaluación toxicológica</span> de la totalidad de las materias primas y activos utilizados en nuestras fórmulas, su adecuación a las diferentes reglamentaciones al momento de su referenciación y durante toda la vida útil del producto.
					<br /><br />
					Este polo realiza una <span className="bold">evaluación del riesgo</span>, esto es, la probabilidad de que aparezca algún peligro durante la utilización del ingrediente/ producto en cuestión.
					<br /><br />
					En el caso de un producto cosmético, no debe existir riesgo alguno para la salud humana.
				</p>
			</PageDisplayImage >

			<PageText>
				<ul className="padding" >
					<li style={{ position: 'relative' }}>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite2_es.png")` }} />
						<div style={{ position: 'absolute', top: '50%', right: '-45px', fontSize: '28px', color: '#c2001a' }}>X</div>
					</li>
					<li style={{ position: 'relative' }}>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite3_es.png")` }} />
						<div style={{ position: 'absolute', top: '50%', right: '-45px', fontSize: '28px', color: '#c2001a' }}>=</div>
					</li>
					<li>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite4_es.png")` }} />
					</li>
				</ul>
			</PageText>

			<PageText backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingBottom" style={{ width: "100%" }}>
					El polo también se encarga de la seguridad de los productos acabados (nuevos y existentes), mediante la <span className="bold">validación toxicológica de las fórmulas</span> desarrollas y de la redacción de <span className="bold">informes de evaluación de la seguridad (RES)</span>.
				</p>

				<div
					className="fullSizeImage"
					style={{ backgroundImage: `url("./images/pages/Securite/securite5_es.png")` }}
				/>

			</PageText >

			<PageDisplayImage imgSrc={"/images/pages/Securite/securite6.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTopBottom" style={{ color: "white" }} >
					LA COSMETOVIGILANCIA
				</h1>
				<p style={{ color: "white" }} >
					Se trata de una <span className="bold">vigilancia</span> realizada mediante un análisis de los <span className="bold">efectos indeseables reportados por los consumidores</span> y observados durante la utilización normal o razonablemente previsible. De esta manera, se contribuye a <span className="bold">mejorar la seguridad</span> de los productos existentes y a la formulación de nuevos productos.
					<br /><br />
					<i>Conviene destacar que una reacción a un producto cosmético no constituye necesariamente una alergia. En el 80% de los casos se trata de reacciones de irritación. </i>
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >VEAMOS LOS OTROS POLOS</h2>
			</PageText>
		</>
	)
}
