import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function LaboRmc() {

	return (
		<>
			<PageTitle>原料中心</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc1.png"} backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
				<p className="paddingTopBottom">
					为了应对娇韵诗不断创新的挑战，我们的配方实验室选择新的原料（活性成分、赋形剂、防腐剂和香料）来提高我们产品的性能、有效性或感官效果。
					<br /><br />
					我们的选择需要遵循严格的标准，例如原产地、天然性和合规性等。
				</p>
				<div id="displayImageHighlight" className="marginTopBottom first" >
					<div className="paddingTopBottom">
						<p>
							RMC（原料中心）实验室是这项活动的核心，它肩负着多项使命：
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc2.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
				<h1 className="paddingTop">
					研究原料的变化
				</h1>
				<p className="paddingTopBottom">
					供应商、生产地点和质量的任何变化都必须经过我们研发专家的研究和验证。
				</p>

				<h1 className="paddingTop">
					活性成分和防腐剂的预配制
				</h1>
				<p className="paddingTop">
					新原材料的初步评估可以及早识别不稳定性和不兼容性，从而节省研发时间。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc3.png"} >
				<>
					<h1 className="paddingTop">
						保证原料供应
					</h1>
					<p className="paddingTopBottom">
						必须批准第二货源和新供应商，以便在常规供应商出现中断状况时，能够保证原料供应。
					</p>

					<h1 className="paddingTop">
						原料数据库
					</h1>
					<p className="paddingTop">
						由于新的法规限制和相关原料的数量，我们对数据管理工具的掌握以及数据的准确性和可靠性至关重要。
					</p>
				</>
			</PageDisplayImage>
		</>
	)
}
