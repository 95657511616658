import { useState, useEffect } from "react";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
//import video from "../../../videoPaths.js"
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function ControleQualite() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	return (
		<>
			<PageTitle>质量控制实验室</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span>
						<span className="first">如今，全球质量标准日益严格，我们必须满足世界各地不同法规的要求。我们的设施受到客户所在国家/地区当局的监管。因此，质量部门的重要性不言而喻，它是我们产品安全的保证。</span>
					</span>
				</p>
			</PageText >

			<PageText>
				<h1 style={{ textTransform: "uppercase", width: "100%", color: "#BA0C2F", textAlign: "left" }}>
					控制实验室参与生产过程的所有阶段……：
				</h1>
			</PageText>

			<PageDisplayImage isVideo={true} videoSrc={video["demoControleQualite1"]} videoSize="small" backgroundImage="none" imageSide="right">
				<p>
					……从原料和包装，到面霜的生产及成品。
					<br /><br />
					每款产品都拥有相应的控制记录表，列出所有必须进行的测试，如感官测试、物理化学测试、微生物学测试或使用测试等。
					<br /><br />
					在通过法规要求以及娇韵诗要求规定的所有测试后，产品方可投放市场。
					<br />
					但检查只是娇韵诗产品质量控制的一小部分。
				</p>
			</PageDisplayImage>

			<PageText style={{ paddingBottom: '100px' }}>
				<h1 className="paddingTop" style={{ width: "100%", color: "#BA0C2F", textTransform: "uppercase" }}>
					质量是整个团队每天的工作：
				</h1>
			</PageText>

			<PageText backgroundColor={"#eee8e2"}>
				<div
					className="fullSizeImage"
					style={{
						backgroundImage: `url("./images/pages/ControleQualite/controleQualite1-ZH.png")`
					}}
				/>
			</PageText >

			<PageVideo videoName={"demoControleQualite2"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} noControls>
			</PageVideo>
		</>
	)
}
