import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Reformulation() {

	return (
		<>
			<PageTitle>REFORMULATION & <br />CATALOGUE</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					The laboratory’s main mission is to ensure the durability of our products on the market.
					<br /><br />
					<span className="bold">Raw material issues are becoming more numerous and more frequent.</span>
					<br /><br />
					These include regulatory constraints, which may impose <span className="bold">restrictions or bans on certain substances</span>.
					<br /><br />
					However, we are also faced with raw material marketing stoppages by our suppliers.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation2.png"} imageSide="center" >
				<p>
					To ensure the longevity of products on the market, the RMC laboratory reformulates products affected by these various issues.
					<br /><br />
					However, <span className="bold">the major challenge of any reformulation is to ensure that a change in formula is imperceptible to our consumers</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation3.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					<span className="bold">Reformulation time varies</span> according to  complexity and deadlines, ranging between 3 and 12 months.
					<br /><br />
					<span className="bold">Reformulation approvals</span> go through a <span className="bold">series of tests</span>, ranging from laboratory trials to efficacy, microbiological and sensory tests.
				</p>
			</PageDisplayImage>
		</>
	)
}
