import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./style.css";
import { userManager } from './oidcConfig';
import Front from "./Front.jsx";

export default function App() {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const login = () => {
        if (!sessionStorage.getItem("loginInProgress")) {
            console.log("Redirecting to login...");
            sessionStorage.setItem("loginInProgress", "true");
            userManager.signinRedirect();
        }
    };

    const logout = () => {
        console.log("Redirecting to logout...");
        userManager.signoutRedirect();
    };

    useEffect(() => {
        const handleRedirectCallback = async () => {
            if (window.location.href.includes('code=')) {
                try {
                    const user = await userManager.signinRedirectCallback();
                    console.log("Login successful");
                    setAuthenticated(true);
                    setUser(user);
                    sessionStorage.removeItem("loginInProgress");
                    navigate("/"); // Redirect after successful login
                } catch (error) {
                    console.error("Error during token exchange:", error);
                    sessionStorage.removeItem("loginInProgress");
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false); // Stop loading if not a login redirect
            }
        };

        handleRedirectCallback();
    }, [navigate]);

    const checkUser = async () => {
        try {
            const currentUser = await userManager.getUser();
            if (currentUser && !currentUser.expired) {
                console.log("User is authenticated");
                setAuthenticated(true);
                setUser(currentUser);
            } else if (!window.location.href.includes('code=') && !sessionStorage.getItem("loginInProgress")) {
                console.log("User not authenticated, initiating login...");
                login();
            }
        } catch (error) {
            console.error("Error checking user:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkUser();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    return <Front />;
}
