import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Prospective() {

	return (
		<>
			<PageTitle>前瞻性（PROSPECTIVE）</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">前瞻性（Prospective）质地实验室有多项任务： </span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective1.png"} imageSide="right">
				<h1 className="paddingTopBottom">
					寻找新的赋形剂
				</h1>
				<p>
					•  用更有效、更环保或更可持续的替代品替换现有赋形剂。
					<br /><br />
					•  新成分可以创造出新质地。
					<br /><br />
					其文件中的每个元素都会经过严格的评估，以确保其符合我们集团的成分政策。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective2.png"} backgroundImage="none" backgroundColor="transparent">
				<h1 className="paddingTopBottom">
					开发前的准备工作
				</h1>
				<p>
					对于品牌雄心勃勃的项目，前瞻性（Prospective）团队进行底层试验，为开发团队的工作提供基础。如果主要活性成分已经确定，该团队将围绕这些成分开始规划产品。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective3.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F">
				<h1 className="paddingTopBottom" style={{ color: "white" }} >
					创造原创配方
				</h1>
				<p style={{ color: "white" }} >
					这些项目没有具体的指导方针，可提供更大的创作自由度，从而有利于全新概念的诞生，这些概念可能会为未来的营销项目要点带来启发。
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }}>探索我们的其他配方实验室</h2>
			</PageText>
		</>
	)
}
