import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Reformulation() {

	return (
		<>
			<PageTitle>重新配方与产品目录</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					实验室的主要任务是确保我们的产品在市场上的持续性。
					<br /><br />
					与我们的原料相关的问题出现频率越来越高。其原因可能涉及监管限制，监管部门可能会对某些物质施加限制或禁令。
					<br /><br />
					我们还可能面临供应商原料停售的情况。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation2.png"} imageSide="center" >
				<p>
					为了确保产品在市场上的持续性，RMC（原料中心）实验室对受这些不同问题影响的产品进行重新配方。
					<br /><br />
					重新配方的主要挑战在于：我们的消费者不应察觉配方的变化。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation3.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					重新配方所需的时间根据其复杂性和紧急程度而变化，从3个月到12个月不等。
					<br /><br />
					重新配方的验证需要经过一系列测试，从稳定性测试到有效性、微生物和感官测试等。
				</p>
			</PageDisplayImage>
		</>
	)
}
