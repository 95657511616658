import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import "../style.css";
import IntroBackground from "../Components/Intro/IntroBackground.jsx";
import { useLanguage } from '../Contexts/LanguageContext.jsx';

export default function EndLoop({ redirection, onChangeVisit, soundOn, onSoundClick, audio, windowRatio }) {

	const { currentLanguage, setCurrentLanguage } = useLanguage()
	const [isMobile, setIsMobile] = useState(false);

	const userAgent = navigator.userAgent;

	const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
	const isMobileDevice = mobileRegex.test(userAgent);

	useEffect(() => {
		if (isMobileDevice) {
			setIsMobile(true);
		}
	}, [isMobileDevice]);

	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	const lineBreak = <br />;

	useEffect(() => {
		setTimeout(() => setShow(true), 500);
	}, []);

	const handleRedirectionClick = () => {
		if (isMobile) {
			let redir = redirection;
			setTimeout(() => window.location.href = redir, 500);
		} else {
			endTransition(redirection);
			onChangeVisit(redirection);
		}
	};

	const handleHomePageClick = () => {
		if (isMobile) {
			setTimeout(() => window.location.href = "/", 500);
		} else {
			endTransition("/");
		}
	};

	const endTransition = (path) => {
		setShow(false);
		gsap.to("#videoRedColor", { opacity: 0, duration: 0.5 });
		setTimeout(() => navigate(path), 500);
		onChangeVisit(redirection);
	}

	return <>
		<div id="videoRedColor-endLoop" />

		<section id="endLoop" className={show ? "show" : "hide"} >
			<h1 className={show ? "show" : "hide"} >
				{currentLanguage == 'fr' && (
					"La visite continue"
				)}

				{currentLanguage == 'es' && (
					"La visita continúa."
				)}

				{currentLanguage == 'cn' && (
					"参观继续。"
				)}

				{currentLanguage == 'en' && (
					"Continue the visit"
				)}
			</h1>
			{redirection === "/usine" ?
				<p className={show ? "show padding" : "hide padding"} >
					{currentLanguage == 'fr' && (
						<>
							Venez découvrir le processus de production des produits {lineBreak}
							Clarins pour mieux connaître ses acteurs
						</>
					)}

					{currentLanguage == 'es' && (
						<>
							Vengan a descubrir el proceso de producción de {lineBreak}
							Clarins para conocer mejor a sus actores.
						</>
					)}

					{currentLanguage == 'cn' && (
						<>来了解娇韵诗的生产过程，更好地认识其参与者。</>
					)}

					{currentLanguage == 'en' && (
						<>
							Discover the manufacturing process behind Clarins {lineBreak}
							products and the people who make it work.
						</>
					)}
				</p>
				:
				<p className={show ? "show padding" : "hide padding"} >
					{currentLanguage == 'fr' && (
						<>
							Venez découvrir le développement des produits {lineBreak}
							Clarins et mieux connaître ses acteurs clés.
						</>
					)}

					{currentLanguage == 'es' && (
						<>
							Vengan a descubrir el desarrollo de los productos {lineBreak}
							Clarins y conocer mejor a sus actores.
						</>
					)}

					{currentLanguage == 'cn' && (
						<>来了解娇韵诗产品的开发过程，更好地认识其关键人物。</>
					)}

					{currentLanguage == 'en' && (
						<>
							Come discover the development of Clarins {lineBreak}
							products and the people who make it work.
						</>
					)}
				</p>
			}

			<button className="bubble" onClick={handleRedirectionClick} >
				{currentLanguage == 'fr' && (
					(redirection === "/usine" ? "Visite de l'usine" : "Visite des laboratoires")
				)}

				{currentLanguage == 'es' && (
					(redirection === "/usine" ? "Tour por la fábrica" : "Visita a los laboratorios")
				)}

				{currentLanguage == 'cn' && (
					(redirection === "/usine" ? "工厂参观" : "实验室参观")
				)}

				{currentLanguage == 'en' && (
					(redirection === "/usine" ? "Visit the factory" : "Visit the laboratories")
				)}
			</button>

			<button className="underline" onClick={handleHomePageClick} >
				<p>
					{currentLanguage == 'fr' && (
						"Retour à l'accueil"
					)}

					{currentLanguage == 'es' && (
						"Retorno al inicio"
					)}

					{currentLanguage == 'cn' && (
						"返回首页"
					)}

					{currentLanguage == 'en' && (
						"Back to home page"
					)}
				</p>
			</button>

			<button id="sound" className="icon" onClick={onSoundClick} >
				<img src={!soundOn ? "/images/general/soundOff.png" : "/images/general/soundOn.png"} />
			</button>
		</section>

		<IntroBackground
			show={show}
			entered={false}
			windowRatio={windowRatio}
			audio={audio}
			soundOn={soundOn}
		/>
	</>
}
