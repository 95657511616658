import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageBanner from "../../../Components/Pages/PageBanner.jsx";
import "../../../style.css";

export default function MaquillageSolaires() {

	return (
		<>
			<PageTitle>MAQUILLAJE <br />Y SOLARES</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Este laboratorio desarrolla todos los productos que contienen <span className="bold">pimientos coloreados</span>, así como todos los productos que incluyen <span className="bold">protección contra los rayos UV</span>.</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Maquillage/maquillage1.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" >
				<h1 className="paddingTopBottom">
					DESARROLLO
					DEL MAQUILLAJE
				</h1>
				<p>
					Nuestros productos de maquillaje combinan resultados de maquillaje inmediatos con la eficacia del tratamiento.<br /><br />
					Su desarrollo supone el mismo rigor que el de los productos de tratamiento, pero implica, además, una <span className="bold">competencia específica de colorista</span>, que permite a nuestros químicos desarrollar tonalidades y cerciorarse de su estabilidad en el tiempo.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Maquillage/maquillage2.png"} >
				<h1 className="paddingTop">
					DESARROLLO DE LOS PRODUCTOS SOLARES
				</h1>
				<p className="paddingTop">
					La formulación de los productos solares es <span className="bold">compleja debido a las exigencias desde el punto de vista de la reglamentación</span> (en algunos países los productos solares pueden considerarse medicamentos) y de carácter técnico.
					<br /><br />
					<span className="bold">Se requieren decenas de tests in vitro y clínicos</span> para validar la protección solar de una fórmula.
				</p>
			</PageDisplayImage>

			<PageBanner imgSrc={"/images/pages/Maquillage/maquillage3.png"} >
				<span className="bold">El logro de altos SPF sin menoscabo de la sensorialidad</span> a la que aspiran nuestros consumidores es un <span className="bold">verdadero reto</span> que nuestros expertos encaran con determinación seleccionando los filtros solares y los emolientes adecuados.
			</PageBanner>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >VEAMOS LOS OTROS LABORATORIOS DE FORMULACIÓN</h2>
			</PageText>
		</>
	)
}
