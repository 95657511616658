import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function StrategieInnovation() {

	return (
		<>
			<PageTitle>我们的承诺</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">欢迎来到我们的研发中心。</span></span></span>
					<br />
					<span><span className="second">Nathalie Issachar，我们的研发总监，将用简短的语言向您介绍我们的活动，以及我们对实现更负责任的美丽所作出的承诺。</span></span>
				</p>
			</PageText>

			<PageVideo videoName={"itwIssachar"}>
				Nathalie Issachar<br />研发总监
			</PageVideo>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements1.png"} backgroundImage="none" backgroundColor="#eee8e2" style={{ marginTop: '100px', paddingTop: '40px' }}>
				<h1 className="paddingTop">
					研究與發展的幾個數字
				</h1>

				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="padding" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left" }} >
							+150
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							研究/年
						</p>
					</div>
				</div>

				<div id="displayImageHighlight">
					<div className="padding" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left" }} >
							+100
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							新產品開發/年
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements2.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingBottom: '100px', paddingTop: '100px' }}>
				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="paddingTopBottom" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left", paddingLeft: "30vw" }} >
							+200
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							嬌韻詩植物標<br />本室中的植物
						</p>
					</div>
				</div>

				<div id="displayImageHighlight">
					<div className="paddingTopBottom" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left", paddingLeft: "30vw" }} >
							+400
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							每年研究新植物
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements3.png"} imageSide="center" >
				<div id="displayImageHighlight">
					<div className="padding">
						<h2 style={{ color: "white", paddingLeft: "2vw", textAlign: "left", width: "45vw" }} >
							+90%
						</h2>
						<p style={{ paddingLeft: "2vw" }} >
							護理中的天然<br />植物成分
						</p>
					</div>
				</div>
			</PageDisplayImage>
		</>
	)
}
