import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Soin() {

	return (
		<>
			<PageTitle>护肤品</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">此实验室开发我们品牌的所有面部或身体护理产品，</span></span>
					<br />
					<span><span className="second">这意味着每年都会推出数十款产品，并进行数百次试验，</span></span>
					<br />
					<span><span className="third">以开发出令消费者满意的理想质地。</span></span>
				</p>
			</PageText>

			<div className="page-block" style={{ position: "absolute", zIndex: 1, padding: 0, top: "20vh", right: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/Soin/soin2.png" style={{ width: "100%" }} />
			</div>

			<PageDisplayImage imgSrc={"/images/pages/Soin/soin1.png"}>
				<p className="paddingTopBottom">
					某些产品需要特殊的专业知识，比如美白产品，它们需要遵守特定的法规，或者泡沫产品。

				</p>
				<div id="displayImageHighlight">
					<div className="paddingTopBottom">
						<p>
							我们主要的挑战是稳定含有许多活性成分的混合物，并在不牺牲产品感官体验的前提下，达到我们品牌设定的远大生态设计目标。
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<div className="page-block" style={{ padding: 0, zIndex: 1, position: "absolute", bottom: "20vh", left: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/Soin/soin3.png" style={{ width: "100%" }} />
			</div>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >探索我们的其他配方实验室</h2>
			</PageText>
		</>
	)
}
