import { useState, useEffect } from "react";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
//import video from "../../../videoPaths.js"
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function DeveloppementPackaging() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	return (
		<>
			<PageTitle>包装设计</PageTitle>

			<PageDisplayImage isVideo={true} videoSrc={video["demoConceptionPackaging1"]} backgroundImage="none" imageSide="right">
				<p>
					包装设计部门的任务是设计能够保护和升华娇韵诗产品的包装。
					<br /><br />
					包装设计由包装材料加工技术的专家 — 娇韵诗项目首席工程师负责，然后与我们的供应商合作开发。
					<br /><br />
					进行严格的多标准认证，以确保包装的性能水平符合预期的质量要求。
				</p>
			</PageDisplayImage>

			<PageDisplayImage isVideo={true} videoSrc={video["demoConceptionPackaging2"]} imageSide="center">
				<h1 className="paddingTopBottom">
					当包装设计完成后，
				</h1>
				<p>
					项目经理确定最终规格，并拟定技术合同，涵盖从包装供应商的生产、在我们工厂的包装到消费者使用的所有特性，确保实现全方位掌控。
				</p>
			</PageDisplayImage>
		</>
	)
}