import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Reformulation() {

	return (
		<>
			<PageTitle>REFORMULACIÓN Y <br />CATÁLOGO</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					El laboratorio tiene como misión esencial garantizar la perennidad de nuestros productos en el mercado.
					<br /><br />
					<span className="bold">Los inconvenientes debidos a las materias primas son cada vez más frecuentes.</span>
					<br /><br />
					Se puede tratar de exigencias reglamentarias que pueden imponer <span className="bold">restricciones o la prohibición de algunas sustancias</span>.
					<br /><br />
					Asimismo puede suceder que nuestros proveedores <span className="bold">suspendan la comercialización de materias primas.</span>
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation2.png"} imageSide="center" >
				<p>
					Con el fin de garantizar la perennidad de los productos en el mercado, el laboratorio RMC reformula los productos concernidos por estas diferentes problemáticas.
					<br /><br />
					El principal reto de cualquier reformulación es que <span className="bold">el cambio de fórmula pase desapercibido ante los consumidores</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation3.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					El tiempo requerido para la reformulación varía en función de su complejidad y de su urgencia; puede ir desde 3 hasta 12 meses.
					<br /><br />
					Las <span className="bold">validaciones de las reformulaciones</span> suponen la realización de <span className="bold">tests</span>, que van desde pruebas de estabilidad hasta tests de eficacia, microbiológicos y sensoriales.
				</p>
			</PageDisplayImage>
		</>
	)
}
