import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageBanner from "../../../Components/Pages/PageBanner.jsx";
import "../../../style.css";

export default function MaquillageSolaires() {

	return (
		<>
			<PageTitle>MAKE-UP <br />& SUN CARE</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">This laboratory develops all products requiring <span className="bold">coloured pigments</span>, in addition to products that include <span className="bold">protection against UV rays</span>.</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Maquillage/maquillage1.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" >
				<h1 className="paddingTopBottom">
					CREATING MAKE-UP
				</h1>
				<p>
					Our make-up products combine immediate make-up results with the effectiveness of skin care.<br /><br />
					Their development requires the <span className="bold">same rigor as</span> for <span className="bold">skin care products</span>, but also involves <span className="bold">specific colour expertise</span>, enabling our chemists to develop shades and check their stability over time.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Maquillage/maquillage2.png"} >
				<h1 className="paddingTop">
					CREATING SUN CARE PRODUCTS
				</h1>
				<p className="paddingTop">
					The formulation of sun care products is <span className="bold">particularly complex, due to regulatory conditions</span> (sun care products may be considered as drugs in some countries) and technical constraints.
					<br /><br />
					<span className="bold">Dozens of trials and tests, both in vitro and clinical,</span> are required to approve a formula.
				</p>
			</PageDisplayImage>

			<PageBanner imgSrc={"/images/pages/Maquillage/maquillage3.png"} >
				<span className="bold">Achieving high SPF</span> without compromising on the high sensoriality quality expected <br />by our consumers is a real challenge that our experts brilliantly meet, by selecting the <br />right sun filters and ingredients.
			</PageBanner>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Discover our other formulation laboratories</h2>
			</PageText>
		</>
	)
}
