import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Soin() {

	return (
		<>
			<PageTitle>TRATAMIENTO</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Este laboratorio desarrolla <span className="bold">la totalidad de los productos de tratamiento rostro y cuerpo de nuestras marcas</span>,</span></span>
					<br />
					<span><span className="second">lo que representa decenas de productos lanzados cada año y varios</span></span>
					<br />
					<span><span className="third">cientos de ensayos para poner a punto la textura ideal que satisfacerá a nuestros consumidores. </span></span>
				</p>
			</PageText>

			<div className="page-block" style={{ position: "absolute", zIndex: 1, padding: 0, top: "20vh", right: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/Soin/soin2.png" style={{ width: "100%" }} />
			</div>

			<PageDisplayImage imgSrc={"/images/pages/Soin/soin1.png"}>
				<p className="paddingTopBottom">
					<span className="bold">Algunos productos requieren una competencia específica</span>, es el caso de los productos aclaradores, que responden a una reglamentación particular o los productos espumantes.
				</p>
				<div id="displayImageHighlight">
					<div className="paddingTopBottom">
						<p>
							Nuestros principales desafíos son la <span className="bold">estabilización de las mezclas que contienen numerosos ingredientes activos y lograr los ambiciosos objetivos de eco-concepción</span> fijados por nuestras marcas sin hacer concesiones en la sensorialidad de los productos.
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<div className="page-block" style={{ padding: 0, zIndex: 1, position: "absolute", bottom: "20vh", left: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/Soin/soin3.png" style={{ width: "100%" }} />
			</div>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >VEAMOS LOS OTROS LABORATORIOS DE FORMULACIÓN</h2>
			</PageText>
		</>
	)
}
