import { useState, useEffect } from "react";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
//import video from "../../../videoPaths.js"
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function ControleQualite() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	return (
		<>
			<PageTitle>Laboratorio de <br />Control de Calidad</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span>
						<span className="first">En la actualidad, las normas de calidad mundiales son cada vez más exigentes y debemos responder a las diferentes reglamentaciones mundiales. Nuestra planta de producción es <span className="bold">inspeccionada</span> por las <span className="bold">autoridades de cada país cliente</span>. De ahí la importancia del departamento de calidad, <span className="bold">garante de la seguridad de nuestros productos</span>.</span>
					</span>
				</p>
			</PageText >

			<PageText>
				<h1 style={{ textTransform: "uppercase", width: "100%", color: "#BA0C2F", textAlign: "left" }}>
					El Laboratorio de Control interviene en todas las etapas del proceso de producción…:
				</h1>
			</PageText>

			<PageDisplayImage isVideo={true} videoSrc={video["demoControleQualite1"]} videoSize="small" backgroundImage="none" imageSide="right">
				<p>
					…materias primas y packagings, cremas fabricadas y productos acabados.
					<br /><br />
					Cada referencia de <b>producto</b> cuenta con su <b>ficha específica de control</b> en la que se describen todos los tests que se van a realizar, tales como los organolépticos, los físico-químicos, los microbiológicos o de utilización, etc.
					<br /><br />
					La <b>validación final</b> de los productos se realiza <b>después de la verificación del conjunto de los tests establecidos por las exigencias reglamentarias, así como por las propias exigencias de Clarins</b>.
					<br />
					El control es sólo una mínima parte de lo que permite garantizar la calidad de los productos en Clarins.
				</p>
			</PageDisplayImage>

			<PageText style={{ paddingBottom: '100px' }}>
				<h1 className="paddingTop" style={{ width: "100%", color: "#BA0C2F", textTransform: "uppercase" }}>
					La <b>Calidad</b> está a cargo de un equipo que trabaja todos los días:
				</h1>
			</PageText>

			<PageText backgroundColor={"#eee8e2"}>
				<div
					className="fullSizeImage"
					style={{
						backgroundImage: `url("./images/pages/ControleQualite/controleQualite1-ES.png")`
					}}
				/>
			</PageText >

			<PageVideo videoName={"demoControleQualite2"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} noControls>
			</PageVideo>
		</>
	)
}
