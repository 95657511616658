import PageText from "../../../Components/Pages/PageText.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";
import { useEffect, useState, useRef } from "react";

export default function Phytochimie() {

	const topRef = useRef()
	const [absoluteImageTop, setAbsoluteImageTop] = useState("20vw")
	useEffect(() => {
		setAbsoluteImageTop(String(topRef.current.getBoundingClientRect().top) + "px")
	}, [])

	return (
		<>
			<PageTitle>植物化学和活性成分</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">植物一直是娇韵诗的灵感源泉，并且仍然是我们研究的核心。 </span></span>
					<br />
					<span><span className="second">Anthonin将更详细地向您解释他的实验室的活动，以及他如何将植物转化为活性提取物。</span></span>
				</p>
			</PageText>

			<div id="phytochimieAbsoluteImages" className="page-block absoluteImage" style={{ top: `${absoluteImageTop}` }} >
				<img src="/images/pages/Phytochimie/phytochimie2.png" />
			</div>
			<div ref={topRef} />

			<PageVideo videoName={"itwGori"} style={{ marginBottom: '100px' }}>
				Anthonin Gori<br />植物化學專案經理
			</PageVideo>

			<PageDisplayImage imageSide="center" imgSrc={"/images/pages/Phytochimie/phytochimie1.png"} title="聚焦提取技术" style={{ paddingTop: '40px' }}>
				<p className="paddingTop">
					我们优先考虑使用环保技术，如微波或超临界二氧化碳技术，通过这些技术，我们可以利用可再生溶剂（如水或植物内部液体）实现广泛的分子提取。
				</p>
			</PageDisplayImage>
		</>
	)
}
