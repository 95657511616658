import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import "../../../style.css";

export default function EvaluationClarins() {

	return (
		<>
			<PageTitle>EVALUATION</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Evaluation/evaluation1.png"} backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					Find out more about two of the Research & Evaluation department's activities:
					<br /><br />
					Dorothée explains how our product <span className="bold">efficacy is assessed</span> using imagery methods.
					<br /><br />
					Louisa shows <span className="bold">how sensorial analysis</span> reveals the sensory profile of our products.
				</p>
			</PageDisplayImage>

			<PageVideo videoName={"itwGodet"} hasSubtitle={true} style={{ paddingBottom: '60px' }}>
				Dorothée Godet<br />IN VIVO EVALUATION DEPARTMENT MANAGER
			</PageVideo>

			<PageVideo videoName={"itwTizi"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px' }}>
				Louisa Tizi<br />IN VIVO EVALUATION PROJECT SUPERVISOR
			</PageVideo>
		</>
	)
}
