import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function BiologieCellulaire() {

	return (
		<>
			<PageTitle>细胞生物学</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Marie将帮助您了解细胞生物学实验室的活动</span></span>
					<br />
					<span><span className="second">您想了解更多关于皮肤样本制备方法的信息吗？</span></span>
				</p>
			</PageText>

			<PageText>
				<div
					style={{
						height: "30vh",
						width: "100%",
						borderRadius: "5px",
						backgroundImage: `url(${"/images/pages/BiologieCellulaire/biologieCellulaire1.png"})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						zIndex: 1
					}}
				/>
			</PageText>

			<PageVideo videoName={"itwDorr1"} style={{ paddingBottom: '100px' }}>
				Marie Dorr<br />体外评估项目负责人
			</PageVideo>

			<PageVideo videoName={"demoExplant"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px' }}>
				皮肤样本的制备方法
			</PageVideo>
		</>
	)
}
