import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import "../../../style.css";

export default function Formulation() {

	return (
		<>
			<PageTitle>FORMULACIÓN</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">¡Bienvenidos a los laboratorios de formulación!</span></span></span>
					<br />
					<span><span className="second">Agnès os presentará su misión dentro del laboratorio de desarrollo tratamiento.
					</span></span>
					<br />
					<span><span className="third">También podréis descubrir las etapas clave de la realización de una emulsión y luego las tareas específicas de nuestros 3 laboratorios de formulación.</span></span>
				</p>
			</PageText>

			<PageVideo videoName={"itwLeFur"} style={{ marginBottom: '100px' }}>
				Agnès Le Fur<br />RESPONSABLE DEL LABORATORIO FORMULACIÓN TRATAMIENTO
			</PageVideo>

			<PageVideo videoName={"demoEmulsion"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				¿CÓMO SE ELABORA UNA EMULSIÓN?
			</PageVideo>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }}>VEAMOS NUESTROS 3 LABORATORIOS DE FORMULACIÓN</h2>
			</PageText>
		</>
	)
}
