import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function MicrobiotePeau() {

	return (
		<>
			<PageTitle>微生物群和皮肤</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">皮肤微生物群代表皮肤表面存在的所有微生物。</span></span>
					<br />
					<span><span className="second">通过我们的研究，我们发现了皮肤的共生微生物，它们在皮肤生态系统中发挥着关键作用。</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/MicrobioteEtPeau/microbioteEtPeau1.png"} imageSide="right" >
				<>
					<p className="paddingTopBottom">
						我们与学术伙伴合作，以了解皮肤微生物群对皮肤主要生物学功能的影响。
					</p>
					<div id="displayImageHighlight" className="marginTopBottom first">
						<div className="paddingTopBottom">
							<p>
								这些研究对我们设计产品至关重要 — 保证微生物群的平衡，并保护对于微生物群与皮肤之间有利相互作用必不可少的哨兵菌株。
							</p>
						</div>
					</div>

					<p className="paddingTopBottom">
						因此，我们发现一种微生物菌株“表皮葡萄球菌” Staphylococcus epidermidis）在调节皮肤保湿方面的作用
					</p>

				</>
			</PageDisplayImage>
		</>
	)
}
