import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function StrategieInnovation() {

	return (
		<>
			<PageTitle>NUESTROS COMPROMISOS</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">Bienvenidos a nuestro centro de Investigación y Desarrollo.</span></span></span>
					<br />
					<span><span className="second">Nathalie Issachar, nuestra Directora de Investigación y Desarrollo, os explicará nuestras actividades y nuestros compromisos para una belleza más responsable.</span></span>
				</p>
			</PageText>

			<PageVideo videoName={"itwIssachar"}>
				Nathalie Issachar<br />DIRECTORA DE INVESTIGACIÓN Y DESARROLLO
			</PageVideo>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements1.png"} backgroundImage="none" backgroundColor="#eee8e2" style={{ marginTop: '100px', paddingTop: '40px' }}>
				<h1 className="paddingTop">
					Investigación y Desarrollo <br />en pocas cifras
				</h1>

				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="padding" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left" }} >
							+150
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							Estudios de investigación / año
						</p>
					</div>
				</div>

				<div id="displayImageHighlight">
					<div className="padding" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left" }} >
							+100
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							Nuevos productos<br />desarrollados/año
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements2.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingBottom: '100px', paddingTop: '100px' }}>
				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="paddingTopBottom" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left", paddingLeft: "30vw" }} >
							+200
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							Plantas en el <br />herbario de Clarins
						</p>
					</div>
				</div>

				<div id="displayImageHighlight">
					<div className="paddingTopBottom" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left", paddingLeft: "30vw" }} >
							+400
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							Plantas en el <br />herbario de Clarins
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements3.png"} imageSide="center" >
				<div id="displayImageHighlight">
					<div className="padding">
						<h2 style={{ color: "white", paddingLeft: "2vw", textAlign: "left", width: "45vw" }} >
							+90%
						</h2>
						<p style={{ paddingLeft: "2vw" }} >
							Ingredientes vegetales de <br />origen natural en el cuidado.

						</p>
					</div>
				</div>
			</PageDisplayImage>
		</>
	)
}
