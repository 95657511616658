import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function SourcingResponsable() {

	return (
		<>
			<PageTitle>负责任采购</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">在选择任何成分，特别是我们的活性成分时，我们遵循公司内部负责任采购章程。</span></span>

				</p>
			</PageText>

			<div className="page-block" style={{ position: "absolute", padding: 0, top: "20vh", right: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/SourcingResponsable/sourcingResponsable4.png" style={{ width: "100%" }} />
			</div>

			<PageText >
				<div className="padding sourcingResponsable1">
					<h1 className="paddingTopBottom">
						3類活性成分及輔料
					</h1>
					<ul className="padding">
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable1.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>植物</p>
							<h4>植物提取物、油、奶油、蠟、精油</h4>
						</li>
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable2.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>天然衍生物</p>
							<h4>源自自然的賦形劑、透過生物技術獲得的成分或非農業來源的劑</h4>
						</li>
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable3.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>合成成分</p>
							<h4>勝肽、合成活性分子、有機防曬劑</h4>
						</li>
					</ul>
				</div>
			</PageText>

			<PageText backgroundImage={"linear-gradient(#eee8e2, #fff)"} >
				<h1 className="paddingTop paddingBottom" style={{ width: "100%", color: "#BA0C2F" }}>
					植物標籤的一個先決條件級別和兩個級別
				</h1>
				<div
					className="fullSizeImage"
					style={{
						backgroundImage: `url("./images/pages/SourcingResponsable/sourcingResponsable5_zh.png")`
					}}
				/>
			</PageText >

		</>
	)
}