import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function LaboRmc() {

	return (
		<>
			<PageTitle>RAW <br /> MATERIAL CENTER</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc1.png"} backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
				<p className="paddingTopBottom">
					Para encarar los retos de innovación permanente en Clarins, nuestros <span className="bold">laboratorios de formulación seleccionan nuevas materias primas</span> (activos, excipientes, conservantes y fragancias), para <span className="bold">mejorar el desempeño, la eficacia o la sensorialidad</span> de nuestros productos.
					<br /><br />
					Nuestras decisiones se basan en criterios estrictos tales como la proveniencia, la naturalidad y la adecuación a las reglamentaciones.
				</p>
				<div id="displayImageHighlight" className="marginTopBottom first" >
					<div className="paddingTopBottom">
						<p>
							El laboratorio RMC (Raw Material Center) constituye la columna vertebral de esta actividad con varios misiones:
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc2.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
				<h1 className="paddingTop">
					EL ESTUDIO DE LOS CAMBIOS DE NUESTRAS MATERIAS PRIMAS
				</h1>
				<p className="paddingTopBottom">
					Cualquier cambio de proveedor, de planta de producción o de calidad debe ser <span className="bold">analizado y valido por nuestros expertos en Investigación y Desarrollo</span>.
				</p>

				<h1 className="paddingTop">
					PRE-FORMULACIÓN DE ACTIVOS Y DE CONSERVANTES

				</h1>
				<p className="paddingTop">
					La evaluación previa de nuevas materias primas permite la <span className="bold">identificación precoz de inestabilidades y de incompatibilidades</span>; de esta manera se ahorra tiempo durante el desarrollo.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc3.png"} >
				<>
					<h1 className="paddingTop">
						GARANTIZAR LOS APROVISIONAMIENTOS
					</h1>
					<p className="paddingTopBottom">
						<span className="bold">La homologación de fuentes alternativas</span>, de nuevos proveedores son necesarias para garantizar el aprovisionamiento en caso de que se agotamiento en nuestros proveedores habituales.
					</p>

					<h1 className="paddingTop">
						BASES DE DATOS MATERIAS PRIMAS
					</h1>
					<p className="paddingTop">
						<span className="bold">El control de nuestras herramientas de gestión de datos</span> , de su exactitud y de su fiabilidad es un asunto de gran importancia debido a las nuevas exigencias reglamentarias y al volumen de materias primas que se manipulan.
					</p>
				</>
			</PageDisplayImage>
		</>
	)
}
