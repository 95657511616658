import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function PoleReglementation() {

	return (
		<>
			<PageTitle>ADECUACIÓN A LA <br />REGLAMENTACIÓN</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Reglementation/reglementation1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					Este polo es el <span className="bold">garante de la información y de la conformidad con la reglamentación relativa a las materias primas, las fórmulas y los productos acabados</span>.
					<br /><br />
					<span className="bold">Las reglamentaciones internacionales son diferentes</span> ; su cometido es regular, prohibir o restringir la utilización de algunos ingredientes, así como de algunas reivindicaciones.
					<br /><br />
					Los expertos de este polo se encargan de <span className="bold">la validación reglamentaria de las fórmulas</span> deben tener en cuenta su región de comercialización y la reglamentación vigente, su galénica, el público objetivo,  la zona de aplicación y las reivindicaciones esperadas.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imageSide="center" imgSrc={"/images/pages/Reglementation/reglementation2.png"} backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingTop" style={{ color: "white" }} >
					También son responsables de la <span className="bold">validación de todos los soportes de comunicación</span> (embalajes, página internet, brochures, redes sociales, etc.) y garantizan que la información probatoria responda a los requerimientos.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >LOS OTROS DOS POLOS</h2>
			</PageText>
		</>
	)
}
