import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function PoleEvaluation() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	return (
		<>
			<PageTitle>安全与监测部</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Securite/securite1.png"} backgroundImage="none" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					安全性评估
				</h1>
				<p className="paddingTop">
					安全评估和监测部门对我们配方中使用的所有原料和活性成分进行毒理学评估，判断其在有效期间和整个生命周期内是否符合各种法规。
					<br /><br />
					进行风险评估，即评估使用特定成分/产品时出现危险的可能性。
					<br /><br />
					与药品不同，化妆品对人类健康的风险必须为零。
				</p>
			</PageDisplayImage >

			<PageText>
				<ul className="padding" >
					<li style={{ position: 'relative' }}>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite2_zh.png")` }} />
						<div style={{ position: 'absolute', top: '50%', right: '-45px', fontSize: '28px', color: '#c2001a' }}>X</div>
					</li>
					<li style={{ position: 'relative' }}>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite3_zh.png")` }} />
						<div style={{ position: 'absolute', top: '50%', right: '-45px', fontSize: '28px', color: '#c2001a' }}>=</div>
					</li>
					<li>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite4_zh.png")` }} />
					</li>
				</ul>
			</PageText>

			<PageText backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingBottom" style={{ width: "100%" }}>
					该部门还负责对研发的配方进行毒理学检验并撰写安全评估报告（RES），以确保成品（新产品和现有产品）的安全性。
				</p>

				<div
					className="fullSizeImage"
					style={{ backgroundImage: `url("./images/pages/Securite/securite5_zh.png")` }}
				/>

			</PageText >

			<PageDisplayImage imgSrc={"/images/pages/Securite/securite6.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTopBottom" style={{ color: "white" }} >
					化妆品不良反应监测
				</h1>
				<p style={{ color: "white" }} >
					针对通过分析消费者报告以及在正常或合理可预见的使用过程中观察到的不良反应而进行的监测。有助于提高现有产品的安全性，以及制定新产品的配方。
					<br /><br />
					<i>请注意： 对化妆品的反应并不一定是过敏反应。事实上，80%的情况都是刺激反应。</i>
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >探索我们的其他部门</h2>
			</PageText>
		</>
	)
}
