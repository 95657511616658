import { useState, useEffect, useRef } from "react";
import PageSubsections from "./PageSubsections.jsx";
import PageGoBack from "./PageGoBack.jsx";
import PageDown from "./PageDown.jsx";
import PageBackground from "./PageBackground.jsx";

import { useLanguage } from '../../Contexts/LanguageContext.jsx';

import NosEngagementsFr from "../../Pages/BlogPages/fr/LabAa-NosEngagements.jsx";
import BiologieCellulaireFr from "../../Pages/BlogPages/fr/LabBa-BiologieCellulaire.jsx";
import MicrobiotePeauFr from "../../Pages/BlogPages/fr/LabBb-MicrobiotePeau.jsx";
import SourcingResponsableFr from "../../Pages/BlogPages/fr/LabCa-SourcingResponsable.jsx";
import PhytochimieFr from "../../Pages/BlogPages/fr/LabCb-Phytochimie.jsx";
import FormulationFr from "../../Pages/BlogPages/fr/LabDa-Formulation.jsx";
import SoinFr from "../../Pages/BlogPages/fr/LabDaa-Soin.jsx";
import MaquillageSolairesFr from "../../Pages/BlogPages/fr/LabDab-MaquillageSolaires.jsx";
import ProspectiveFr from "../../Pages/BlogPages/fr/LabDac-Prospective.jsx";
import RMCFr from "../../Pages/BlogPages/fr/LabDb-RMC.jsx";
import MicrobiologieFr from "../../Pages/BlogPages/fr/LabDc-Microbiologie.jsx";
import EvaluationFr from "../../Pages/BlogPages/fr/LabEa-Evaluation.jsx";
import AresFr from "../../Pages/BlogPages/fr/LabEb-Ares.jsx";
import SecuriteVigilancesFr from "../../Pages/BlogPages/fr/LabEba-SecuriteVigilances.jsx";
import ReglementationConformiteFr from "../../Pages/BlogPages/fr/LabEbb-ReglementationConformite.jsx";
import EnregistrementsInternationauxFr from "../../Pages/BlogPages/fr/LabEbc-EnregistrementsInternationaux.jsx";
import ReformulationFr from "../../Pages/BlogPages/fr/LabEc-Reformulation.jsx";
import ControleQualiteFr from "../../Pages/BlogPages/fr/UsiA-ControleQualite.jsx";
import ConceptionPackagingFr from "../../Pages/BlogPages/fr/UsiB-ConceptionPackaging.jsx";
import ConditionnementFr from "../../Pages/BlogPages/fr/UsiC-Conditionnement.jsx";

import NosEngagementsEn from "../../Pages/BlogPages/en/LabAa-NosEngagements.jsx";
import BiologieCellulaireEn from "../../Pages/BlogPages/en/LabBa-BiologieCellulaire.jsx";
import MicrobiotePeauEn from "../../Pages/BlogPages/en/LabBb-MicrobiotePeau.jsx";
import SourcingResponsableEn from "../../Pages/BlogPages/en/LabCa-SourcingResponsable.jsx";
import PhytochimieEn from "../../Pages/BlogPages/en/LabCb-Phytochimie.jsx";
import FormulationEn from "../../Pages/BlogPages/en/LabDa-Formulation.jsx";
import SoinEn from "../../Pages/BlogPages/en/LabDaa-Soin.jsx";
import MaquillageSolairesEn from "../../Pages/BlogPages/en/LabDab-MaquillageSolaires.jsx";
import ProspectiveEn from "../../Pages/BlogPages/en/LabDac-Prospective.jsx";
import RMCEn from "../../Pages/BlogPages/en/LabDb-RMC.jsx";
import MicrobiologieEn from "../../Pages/BlogPages/en/LabDc-Microbiologie.jsx";
import EvaluationEn from "../../Pages/BlogPages/en/LabEa-Evaluation.jsx";
import AresEn from "../../Pages/BlogPages/en/LabEb-Ares.jsx";
import SecuriteVigilancesEn from "../../Pages/BlogPages/en/LabEba-SecuriteVigilances.jsx";
import ReglementationConformiteEn from "../../Pages/BlogPages/en/LabEbb-ReglementationConformite.jsx";
import EnregistrementsInternationauxEn from "../../Pages/BlogPages/en/LabEbc-EnregistrementsInternationaux.jsx";
import ReformulationEn from "../../Pages/BlogPages/en/LabEc-Reformulation.jsx";
import ControleQualiteEn from "../../Pages/BlogPages/en/UsiA-ControleQualite.jsx";
import ConceptionPackagingEn from "../../Pages/BlogPages/en/UsiB-ConceptionPackaging.jsx";
import ConditionnementEn from "../../Pages/BlogPages/en/UsiC-Conditionnement.jsx";

import NosEngagementsZh from "../../Pages/BlogPages/zh/LabAa-NosEngagements.jsx";
import BiologieCellulaireZh from "../../Pages/BlogPages/zh/LabBa-BiologieCellulaire.jsx";
import MicrobiotePeauZh from "../../Pages/BlogPages/zh/LabBb-MicrobiotePeau.jsx";
import SourcingResponsableZh from "../../Pages/BlogPages/zh/LabCa-SourcingResponsable.jsx";
import PhytochimieZh from "../../Pages/BlogPages/zh/LabCb-Phytochimie.jsx";
import FormulationZh from "../../Pages/BlogPages/zh/LabDa-Formulation.jsx";
import SoinZh from "../../Pages/BlogPages/zh/LabDaa-Soin.jsx";
import MaquillageSolairesZh from "../../Pages/BlogPages/zh/LabDab-MaquillageSolaires.jsx";
import ProspectiveZh from "../../Pages/BlogPages/zh/LabDac-Prospective.jsx";
import RMCZh from "../../Pages/BlogPages/zh/LabDb-RMC.jsx";
import MicrobiologieZh from "../../Pages/BlogPages/zh/LabDc-Microbiologie.jsx";
import EvaluationZh from "../../Pages/BlogPages/zh/LabEa-Evaluation.jsx";
import AresZh from "../../Pages/BlogPages/zh/LabEb-Ares.jsx";
import SecuriteVigilancesZh from "../../Pages/BlogPages/zh/LabEba-SecuriteVigilances.jsx";
import ReglementationConformiteZh from "../../Pages/BlogPages/zh/LabEbb-ReglementationConformite.jsx";
import EnregistrementsInternationauxZh from "../../Pages/BlogPages/zh/LabEbc-EnregistrementsInternationaux.jsx";
import ReformulationZh from "../../Pages/BlogPages/zh/LabEc-Reformulation.jsx";
import ControleQualiteZh from "../../Pages/BlogPages/zh/UsiA-ControleQualite.jsx";
import ConceptionPackagingZh from "../../Pages/BlogPages/zh/UsiB-ConceptionPackaging.jsx";
import ConditionnementZh from "../../Pages/BlogPages/zh/UsiC-Conditionnement.jsx";

import NosEngagementsEs from "../../Pages/BlogPages/es/LabAa-NosEngagements.jsx";
import BiologieCellulaireEs from "../../Pages/BlogPages/es/LabBa-BiologieCellulaire.jsx";
import MicrobiotePeauEs from "../../Pages/BlogPages/es/LabBb-MicrobiotePeau.jsx";
import SourcingResponsableEs from "../../Pages/BlogPages/es/LabCa-SourcingResponsable.jsx";
import PhytochimieEs from "../../Pages/BlogPages/es/LabCb-Phytochimie.jsx";
import FormulationEs from "../../Pages/BlogPages/es/LabDa-Formulation.jsx";
import SoinEs from "../../Pages/BlogPages/es/LabDaa-Soin.jsx";
import MaquillageSolairesEs from "../../Pages/BlogPages/es/LabDab-MaquillageSolaires.jsx";
import ProspectiveEs from "../../Pages/BlogPages/es/LabDac-Prospective.jsx";
import RMCEs from "../../Pages/BlogPages/es/LabDb-RMC.jsx";
import MicrobiologieEs from "../../Pages/BlogPages/es/LabDc-Microbiologie.jsx";
import EvaluationEs from "../../Pages/BlogPages/es/LabEa-Evaluation.jsx";
import AresEs from "../../Pages/BlogPages/es/LabEb-Ares.jsx";
import SecuriteVigilancesEs from "../../Pages/BlogPages/es/LabEba-SecuriteVigilances.jsx";
import ReglementationConformiteEs from "../../Pages/BlogPages/es/LabEbb-ReglementationConformite.jsx";
import EnregistrementsInternationauxEs from "../../Pages/BlogPages/es/LabEbc-EnregistrementsInternationaux.jsx";
import ReformulationEs from "../../Pages/BlogPages/es/LabEc-Reformulation.jsx";
import ControleQualiteEs from "../../Pages/BlogPages/es/UsiA-ControleQualite.jsx";
import ConceptionPackagingEs from "../../Pages/BlogPages/es/UsiB-ConceptionPackaging.jsx";
import ConditionnementEs from "../../Pages/BlogPages/es/UsiC-Conditionnement.jsx";

import { gsap } from "gsap";
import "../../style.css";

export default function PageTemplate({ chapter, visitPath, parentPath, subSections, windowRatio, file }) {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	let NosEngagements;
	let BiologieCellulaire;
	let MicrobiotePeau;
	let SourcingResponsable;
	let Phytochimie;
	let Formulation;
	let Soin;
	let MaquillageSolaires;
	let Prospective;
	let RMC;
	let Microbiologie;
	let Evaluation;
	let Ares;
	let SecuriteVigilances;
	let ReglementationConformite;
	let EnregistrementsInternationaux;
	let Reformulation;
	let ControleQualite;
	let ConceptionPackaging;
	let Conditionnement;

	if (currentLanguage === 'fr') {
		NosEngagements = NosEngagementsFr;
		BiologieCellulaire = BiologieCellulaireFr;
		MicrobiotePeau = MicrobiotePeauFr;
		SourcingResponsable = SourcingResponsableFr;
		Phytochimie = PhytochimieFr;
		Formulation = FormulationFr;
		Soin = SoinFr;
		MaquillageSolaires = MaquillageSolairesFr;
		Prospective = ProspectiveFr;
		RMC = RMCFr;
		Microbiologie = MicrobiologieFr;
		Evaluation = EvaluationFr;
		Ares = AresFr;
		SecuriteVigilances = SecuriteVigilancesFr;
		ReglementationConformite = ReglementationConformiteFr;
		EnregistrementsInternationaux = EnregistrementsInternationauxFr;
		Reformulation = ReformulationFr;
		ControleQualite = ControleQualiteFr;
		ConceptionPackaging = ConceptionPackagingFr;
		Conditionnement = ConditionnementFr;
	} else if (currentLanguage === 'es') {
		NosEngagements = NosEngagementsEs;
		BiologieCellulaire = BiologieCellulaireEs;
		MicrobiotePeau = MicrobiotePeauEs;
		SourcingResponsable = SourcingResponsableEs;
		Phytochimie = PhytochimieEs;
		Formulation = FormulationEs;
		Soin = SoinEs;
		MaquillageSolaires = MaquillageSolairesEs;
		Prospective = ProspectiveEs;
		RMC = RMCEs;
		Microbiologie = MicrobiologieEs;
		Evaluation = EvaluationEs;
		Ares = AresEs;
		SecuriteVigilances = SecuriteVigilancesEs;
		ReglementationConformite = ReglementationConformiteEs;
		EnregistrementsInternationaux = EnregistrementsInternationauxEs;
		Reformulation = ReformulationEs;
		ControleQualite = ControleQualiteEs;
		ConceptionPackaging = ConceptionPackagingEs;
		Conditionnement = ConditionnementEs;
	} else if (currentLanguage === 'cn') {
		NosEngagements = NosEngagementsZh;
		BiologieCellulaire = BiologieCellulaireZh;
		MicrobiotePeau = MicrobiotePeauZh;
		SourcingResponsable = SourcingResponsableZh;
		Phytochimie = PhytochimieZh;
		Formulation = FormulationZh;
		Soin = SoinZh;
		MaquillageSolaires = MaquillageSolairesZh;
		Prospective = ProspectiveZh;
		RMC = RMCZh;
		Microbiologie = MicrobiologieZh;
		Evaluation = EvaluationZh;
		Ares = AresZh;
		SecuriteVigilances = SecuriteVigilancesZh;
		ReglementationConformite = ReglementationConformiteZh;
		EnregistrementsInternationaux = EnregistrementsInternationauxZh;
		Reformulation = ReformulationZh;
		ControleQualite = ControleQualiteZh;
		ConceptionPackaging = ConceptionPackagingZh;
		Conditionnement = ConditionnementZh;
	} else {
		NosEngagements = NosEngagementsEn;
		BiologieCellulaire = BiologieCellulaireEn;
		MicrobiotePeau = MicrobiotePeauEn;
		SourcingResponsable = SourcingResponsableEn;
		Phytochimie = PhytochimieEn;
		Formulation = FormulationEn;
		Soin = SoinEn;
		MaquillageSolaires = MaquillageSolairesEn;
		Prospective = ProspectiveEn;
		RMC = RMCEn;
		Microbiologie = MicrobiologieEn;
		Evaluation = EvaluationEn;
		Ares = AresEn;
		SecuriteVigilances = SecuriteVigilancesEn;
		ReglementationConformite = ReglementationConformiteEn;
		EnregistrementsInternationaux = EnregistrementsInternationauxEn;
		Reformulation = ReformulationEn;
		ControleQualite = ControleQualiteEn;
		ConceptionPackaging = ConceptionPackagingEn;
		Conditionnement = ConditionnementEn;
	}

	const [height, setHeight] = useState(0);
	const [scrollUpdate, setScrollUpdate] = useState(0);
	const [show, setShow] = useState(false);
	const pageContent = useRef();
	const scrollDiv = useRef();

	// When a new page is opened, scroll to top, and reset all apparition animations
	useEffect(() => {
		scrollDiv.current.scrollTo(0, 0);
		document.querySelectorAll(".page-block").forEach(element => element.classList.remove("animated"));
		document.querySelectorAll("#page-background").forEach(element => element.classList.remove("animated"));

		setShow(false);
		setTimeout(() => {
			setShow(true);
		}, 1500)
	}, [location.pathname]);

	//When a new page is opened, set the height of the background to fit all the page's content
	useEffect(() => {
		setHeight(getHeight());
		scrollDiv.current.scrollTo(0, 0);
	}, [windowRatio, location.pathname]);

	// Makes the page blocks appear progressively when the user scrolls
	useEffect(() => {
		if (show) {
			var blocks = document.querySelectorAll(".page-block");
			var backgrounds = document.querySelectorAll("#page-background");

			var windowHeight = window.innerHeight;
			var elementVisible = 90;

			for (var i = 0; i < blocks.length; i++) {
				var elementTop = blocks[i].getBoundingClientRect().top;
				if (elementTop < windowHeight - elementVisible) {
					blocks[i].classList.add("animated");
				}
			}
			for (var i = 0; i < backgrounds.length; i++) {
				var elementTop = backgrounds[i].getBoundingClientRect().top;
				if (elementTop < windowHeight - elementVisible) {
					backgrounds[i].classList.add("animated");
				}
			}

		}
	}, [scrollUpdate, show]);

	//Calculate the total height of the page's content to send to pageBackground.jsx,
	//to make a svg overlay of the right size
	const getHeight = () => {
		var totalHeight = 0;
		for (let i = 0; i < pageContent.current.children.length; i++) {
			totalHeight += pageContent.current.children[i].clientHeight;
		}
		return totalHeight;
	}

	//Page closing fade out animation
	const handlePageClosing = () => {
		document.querySelectorAll(".page-block").forEach(element => element.classList.remove("animated"));
		document.querySelectorAll("#page-background").forEach(element => element.classList.remove("animated"));
		document.querySelectorAll("#page-title-wrapper").forEach(element => element.classList.add("endAnimation"));
		document.querySelectorAll(".no-animation").forEach(element => element.classList.add("endAnimation"));

		gsap.to(pageContent.current, { opacity: 0, ease: "none", delay: 0.5, duration: 1 });
	};

	return (<>
		<div id="pageWrapper" ref={scrollDiv} onScroll={() => setScrollUpdate(scrollUpdate => scrollUpdate + 1)}>
			<section id="pageContent" ref={pageContent} >
				{
					file === "NosEngagements" ? <NosEngagements /> :
						file === "BiologieCellulaire" ? <BiologieCellulaire /> :
							file === "MicrobiotePeau" ? <MicrobiotePeau /> :
								file === "SourcingResponsable" ? <SourcingResponsable /> :
									file === "Phytochimie" ? <Phytochimie /> :
										file === "Formulation" ? <Formulation /> :
											file === "Soin" ? <Soin /> :
												file === "MaquillageSolaires" ? <MaquillageSolaires /> :
													file === "Prospective" ? <Prospective /> :
														file === "RMC" ? <RMC /> :
															file === "Microbiologie" ? <Microbiologie /> :
																file === "Evaluation" ? <Evaluation /> :
																	file === "Ares" ? <Ares /> :
																		file === "SecuriteVigilances" ? <SecuriteVigilances /> :
																			file === "ReglementationConformite" ? <ReglementationConformite /> :
																				file === "EnregistrementsInternationaux" ? <EnregistrementsInternationaux /> :
																					file === "Reformulation" ? <Reformulation /> :
																						file === "ControleQualite" ? <ControleQualite /> :
																							file === "ConceptionPackaging" ? <ConceptionPackaging /> :
																								<Conditionnement />

				}
				{subSections && <PageSubsections subSections={subSections} />}
				{parentPath && <PageGoBack parentPath={parentPath} />}

				<PageDown />

			</section>

			<PageBackground
				height={height}
				chapter={chapter}
				onPageClosing={handlePageClosing}
				windowRatio={windowRatio}
				visitPath={visitPath}
			/>
		</div>
	</>
	)
}