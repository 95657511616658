import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Conditionnement() {

	return (
		<>
			<PageTitle>Envasado</PageTitle>

			<PageText style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingBottom" style={{ width: "100%", color: "#BA0C2F", textAlign: "left", textTransform: "uppercase" }}>
					¿Cómo se desarrolla el envasado de los productos?
				</h1>
				<p>
					<span className="bold">Más de 200 personas</span> : operarios, encargados del envasado, técnicos de mantenimiento y de supervisión trabajan durante las <span className="bold">24 horas</span> en <span className="bold">3 turnos</span>, incluido uno de noche reducida.
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement1.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					El envasado comprende el ensamblaje de los productos acabados
				</h1>
				<p className="paddingTop">
					Tubos, frascos, tarros a partir de un producto no empacado que proviene de la fabricación y de diferentes artículos de envasado.
					<br /><br />
					Los productos acabados, dispuestos en cajas, se   agrupan en paletas que se envían a nuestro centro de logística en Amiens.
					<br /><br />
					Las <span className="bold">25 líneas de producción</span> son específicas para tipos de packaging tales como los tubos, las muestras, los tarros o los frascos que van desde 3 ml hasta 400 ml; no existe una línea específica para tubos ni otra exclusiva para lociones.
					<br /><br />
					Sólo unas cuantas líneas pueden realizar dos tipos de packaging, pero todas producen varias presentaciones.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement2.png"} >
				<h1 className="paddingTop">
					Cada vez las líneas son más <b>robotizadas</b> y están equipadas con un <b>sistema de control por visión.</b>
				</h1>
				<p className="paddingTop" >
					La misión principal de los operarios es supervisar el funcionamiento de las líneas, aprovisionarlas y controlar los productos fabricados según la utilización que hará de ellos nuestra futura clienta.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement3.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					Dado el considerable número de referencias diferentes que se producen…
				</h1>
				<p className="paddingTop">
					…… cerca de <span className="bold">3.000</span>, los cambios de un producto a otro son muy frecuentes.
					<br /><br />
					Al <span className="bold">ﬁnal de una producción</span>, todos los equipos que entran en contacto con las cremas o las lociones se <span className="bold">desarman</span> para ser <span className="bold">limpiados</span>.
					<br /><br />
					Además, todas las graduaciones de las máquinas deben modificarse con el fin de producir el próximo producto acabado. Por lo tanto, es poco común que una misma producción (cambio de lote) dure más de un día.
				</p>
			</PageDisplayImage >
		</>
	)
}
