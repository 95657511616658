import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Ares() {

	return (
		<>
			<PageTitle>ARES</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Ares/ares1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					<span className="bold">ARES es el acrónimo de <q>"Affaires Réglementaires et Évaluation de la sécurité"</q> (Asuntos Reglamentarios y de Evaluación de la Seguridad).</span>
					<br /><br />
					Se trata de un servicio encargado de la <span className="bold">seguridad y conformidad de nuestros productos con las reglamentaciones vigentes en los países</span> en los cuales los comercializamos.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} > VEAMOS NUESTROS DIFERENTES POLOS</h2>
			</PageText>
		</>
	)
}
