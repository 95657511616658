import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import "../../../style.css";

export default function EvaluationClarins() {

	return (
		<>
			<PageTitle>EVALUACIÓN</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Evaluation/evaluation1.png"} backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					Ahora veamos más en detalle dos actividades del Departamento de Investigación y Evaluación.
					<br /><br />
					Con Dorothée: <span className="bold">la evaluación de la eficacia de nuestros productos con ayuda de métodos de imaginería</span>.
					<br /><br />
					Con Louisa: <span className="bold">el análisis sensorial</span> que permite determinar el <span className="bold">perfil sensorial</span> de nuestros productos.
				</p>
			</PageDisplayImage>

			<PageVideo videoName={"itwGodet"} hasSubtitle={true} style={{ paddingBottom: '60px' }}>
				Dorothée Godet<br />RESPONSABLE DE EVALUACIÓN VIVO
			</PageVideo>

			<PageVideo videoName={"itwTizi"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px' }}>
				Louisa Tizi<br />ENCARGADA DE PROYECTOS EVALUACIÓN VIVO
			</PageVideo>
		</>
	)
}
