import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Ares() {

	return (
		<>
			<PageTitle>ARES</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Ares/ares1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					<span className="bold">ARES IS THE ACRONYM IN FRENCH FOR <q>REGULATORY AFFAIRS AND SAFETY EVALUATION</q>.</span>
					<br /><br />
					This department ensures the <span className="bold">safety and compliance of our products with regulations</span> that exist in countries where are products are sold, both <u>before and after</u> they are placed on the market.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} > Discover ARES activities</h2>
			</PageText>
		</>
	)
}
