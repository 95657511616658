import { useState, useEffect, useMemo } from "react";

import "../style.css";
import Visit from "../Components/Visit/Visit.jsx"
//import video from "../videoPaths.js"

import { useLanguage } from '../Contexts/LanguageContext.jsx';

export default function Laboratoires({ windowRatio, soundOn, onSoundClick }) {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = (currentLanguage == 'fr') ? import('/videoPaths-fr.js') : (currentLanguage == 'es') ? import('/videoPaths-es.js') : (currentLanguage == 'cn') ? import('/videoPaths-cn.js') : import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	const videoPartsLaboRef = (currentLanguage == 'fr') ? import('/videoParts-labo-fr.js') : (currentLanguage == 'es') ? import('/videoParts-labo-es.js') : (currentLanguage == 'cn') ? import('/videoParts-labo-cn.js') : import('/videoParts-labo-en.js');

	const [videoPartsLabo, setVideoPartsLabo] = useState([]);
	const [videoPartsUsine, setVideoPartsUsine] = useState([]);

	videoPartsLaboRef.then((videoPartsLaboEl) => {
		setVideoPartsLabo(videoPartsLaboEl.default);
	});

	return <>
		<Visit
			windowRatio={windowRatio}
			soundOn={soundOn}
			path={"/laboratoires"}
			videoParts={videoPartsLabo}
			videoPath={video.laboratoires}
			onSoundClick={(soundOn) => onSoundClick(soundOn)}
		/>
	</>
}
