import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function PoleEnregistrements() {

	return (
		<>
			<PageTitle> 国际注册与产品信息文件（DIP）部</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Enregistrements/enregistrements1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					国际注册与产品信息文件部负责完成将化妆品产品推向不同分销国家/地区市场所需的前期行政手续。
					<br /><br />
					因此，该团队负责确保上市化妆品产品的DIP（产品信息文件）符合法规要求，并处理原材料和产品的卫生注册管理。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Enregistrements/enregistrements2.png"} style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingTopBottom">
					DIP（产品信息文件）是产品的“身份证”，是产品上市销售的必要条件。 根据监管机构要求，它必须包含来自不同参与部门（研发、运营、试产、生产、质量等）的以下信息：
				</p>
				<div id="displayImageHighlight" className="marginTopBottom first">
					<div className="paddingTopBottom">
						<p>
							•  产品的标识和描述（配方、规格、标签等）
							<br /><br />
							• 安全评估报告，包括稳定性、兼容性、微生物安全性、无毒性、化妆品安全监测反馈等结果。
							<br /><br />
							• 所宣传功效的证据
							<br /><br />
							• 生产方法的描述和一份符合良好生产规范（BPF）的合规声明。
						</p>
					</div>
				</div>
				<p className="paddingTopBottom">
					该部门确保产品在上市前DIP（产品信息文件）的完整性，并负责在产品市场生命周期内、甚至停止销售后10年内保持更新。
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >探索我们的其他部门</h2>
			</PageText>
		</>
	)
}
