import { useState, useEffect } from "react";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
//import video from "../../../videoPaths.js"
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function DeveloppementPackaging() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	return (
		<>
			<PageTitle>Concepción del packaging</PageTitle>

			<PageDisplayImage isVideo={true} videoSrc={video["demoConceptionPackaging1"]} backgroundImage="none" imageSide="right">
				<p>
					La misión del servicio desarrollo packaging es <span className="bold">concebir los packagings que protegerán y realzarán los productos Clarins</span>.
					<br /><br />
					La concepción está a cargo de los ingenieros jefe de proyecto Clarins, <span className="bold">especialistas</span> en todas las <span className="bold">tecnologías de transformación de los materiales de embalaje</span>; a continuación, conjuntamente con nuestros proveedores, se desarrollan los packagings.
					<br /><br />
					Se lleva a cabo una <span className="bold">rigurosa homologación multi-criterios</span> con el fin de garantizar que el desempeño del packaging corresponda a los <span className="bold">criterios de calidad previamente definidos</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage isVideo={true} videoSrc={video["demoConceptionPackaging2"]} imageSide="center">
				<h1 className="paddingTopBottom">
					Cuando se ha definido el packaging,
				</h1>
				<p>
					El jefe de proyecto termina la <span className="bold">especiﬁcación</span>, la cual se convertirá en el <span className="bold">contrato técnico</span> que incluirá todas las características que garantizarán un <span className="bold">perfecto control</span> del proceso desde la <span className="bold">producción</span> por parte del <span className="bold">proveedor del packaging</span>, pasando por el envasado en nuestras plantas de producción, <span className="bold">hasta la utilización por parte del consumidor</span>.
				</p>
			</PageDisplayImage>
		</>
	)
}