import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function SourcingResponsable() {

	return (
		<>
			<PageTitle>Responsible <br />SOURCING</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">When selecting all the ingredients we use, especially our active ingredients, we respect our</span></span>
					<br />
					<span><span className="second"><span className="bold">internal responsible sourcing charter</span>.</span></span>
				</p>
			</PageText>

			<div className="page-block" style={{ position: "absolute", padding: 0, top: "20vh", right: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/SourcingResponsable/sourcingResponsable4.png" style={{ width: "100%" }} />
			</div>

			<PageText >
				<div className="padding sourcingResponsable1">
					<h1 className="paddingTopBottom">
						3 categories of active ingredients and excipients
					</h1>
					<ul className="padding">
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable1.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Plants</p>
							<h4>Plant extracts, oils, butters, waxes, essential oils</h4>
						</li>
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable2.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Natural derivatives</p>
							<h4>Excipients derived from nature, ingredients obtained by biotechnology or non-agro-sourced solvent</h4>
						</li>
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable3.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Synthetic ingredients</p>
							<h4>Peptides, synthetic active molecules, organic sun filters, …</h4>
						</li>
					</ul>
				</div>
			</PageText>

			<PageText backgroundImage={"linear-gradient(#eee8e2, #fff)"} >
				<h1 className="paddingBottom" style={{ width: "100%", color: "#BA0C2F" }}>
					ONE PRE-REQUISITE LEVEL AND TWO LEVELS <br /> OF LABELING FOR PLANTS
				</h1>
				<div
					className="fullSizeImage"
					style={{
						backgroundImage: `url("./images/pages/SourcingResponsable/sourcingResponsable5_en.png")`
					}}
				/>
			</PageText >

		</>
	)
}
