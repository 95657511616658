import PageText from "../../../Components/Pages/PageText.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";
import { useEffect, useState, useRef } from "react";

export default function Phytochimie() {

	const topRef = useRef()
	const [absoluteImageTop, setAbsoluteImageTop] = useState("20vw")
	useEffect(() => {
		setAbsoluteImageTop(String(topRef.current.getBoundingClientRect().top) + "px")
	}, [])

	return (
		<>
			<PageTitle>FITOQUÍMICA <br />Y ACTIVOS</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Las plantas siempre han sido una <span className="bold">fuente de inspiración</span> para Clarins y están en el <span className="bold">centro de nuestras investigaciones</span>.</span></span>
					<br />
					<span><span className="second">Anthonin os explicará más en detalle las actividades de su laboratorio y <span className="bold">cómo éste transforma las plantas en extractos activos</span>.</span></span>
				</p>
			</PageText>

			<div id="phytochimieAbsoluteImages" className="page-block absoluteImage" style={{ top: `${absoluteImageTop}` }} >
				<img src="/images/pages/Phytochimie/phytochimie2.png" />
			</div>
			<div ref={topRef} />

			<PageVideo videoName={"itwGori"} style={{ marginBottom: '100px' }}>
				Anthonin Gori<br />Gerente de Proyectos de Fitoquímica
			</PageVideo>

			<PageDisplayImage imageSide="center" imgSrc={"/images/pages/Phytochimie/phytochimie1.png"} title="TÉCNICAS DE EXTRACCIÓN" style={{ paddingTop: '40px' }}>
				<p className="paddingTop">
					Damos prioridad a las técnicas eco-responsables, como la utilización de <span className="bold">microondas</span> o de <span className="bold">CO2 supercrítico</span>, las cuales permiten extraer un amplio espectro de moléculas utilizando <span className="bold">solventes renovables</span> como el agua o los líquidos internos de las plantas.
				</p>
			</PageDisplayImage>
		</>
	)
}
