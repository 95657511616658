import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function MicrobiotePeau() {

	return (
		<>
			<PageTitle>MICROBIOTA<br /> Y PIEL</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">La microbiota cutánea está conformada por todos los microorganismos presentes en la superficie de la piel.</span></span>
					<br />
					<span><span className="second">Con nuestras investigaciones, ponemos en evidencia los microorganismos comensales de la piel que tienen un <b>rol clave en el ecosistema cutáneo</b>.</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/MicrobioteEtPeau/microbioteEtPeau1.png"} imageSide="right" >
				<>
					<p className="paddingTopBottom">
						Trabajamos con interlocutores académicos para <b>comprender el impacto de la microbiota cutánea en las principales funciones biológicas de la piel.</b>
					</p>
					<div id="displayImageHighlight" className="marginTopBottom first">
						<div className="paddingTopBottom">
							<p>
								Estas investigaciones son indispensables para ayudarnos en la concepción de nuestros productos y, así, <b>garantizar el equilibrio de la microbiota y preservar las cepas centinelas</b>, indispensables para el funcionamiento óptimo de las interacciones entre la microbiota y la piel.
							</p>
						</div>
					</div>

					<p className="paddingTopBottom">
						Es así que pudimos evidenciar el rol de una cepa microbiana, la “Staphylococcus epidermidis” en la regulación de la hidratación.
					</p>

				</>
			</PageDisplayImage>
		</>
	)
}
