import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import "../../../style.css";

export default function EvaluationClarins() {

	return (
		<>
			<PageTitle>评估</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Evaluation/evaluation1.png"} backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					更详细地了解研究与评估部门的两项活动。
					<br /><br />
					与Dorothée一起，使用成像方法评估我们产品的有效性
					<br /><br />
					与Louisa一起，进行感官分析，以描述我们产品的感官特性。
				</p>
			</PageDisplayImage>

			<PageVideo videoName={"itwGodet"} hasSubtitle={true} style={{ paddingBottom: '60px' }}>
				Dorothée Godet<br />体内评估负责人
			</PageVideo>

			<PageVideo videoName={"itwTizi"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px' }}>
				Louisa Tizi<br />体内评估项目负责人
			</PageVideo>
		</>
	)
}
