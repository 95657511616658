import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function SourcingResponsable() {

	return (
		<>
			<PageTitle>APROVISIONAMIENTO <br />RESPONSABLE</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Para la escogencia de cualquier ingrediente y especialmente de los ingredientes activos respetamos nuestra</span></span>
					<br />
					<span><span className="second"><span className="bold">carta interna de aprovisionamiento responsable</span>.</span></span>
				</p>
			</PageText>

			<div className="page-block" style={{ position: "absolute", padding: 0, top: "20vh", right: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/SourcingResponsable/sourcingResponsable4.png" style={{ width: "100%" }} />
			</div>

			<PageText >
				<div className="padding sourcingResponsable1">
					<h1 className="paddingTopBottom">
						3 categorías de ingredientes activos y excipientes.
					</h1>
					<ul className="padding">
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable1.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Plantas</p>
							<h4>Extractos de plantas, aceites, mantecas, ceras, aceites esenciales.</h4>
						</li>
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable2.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Derivados naturales</p>
							<h4>Excipientes derivados de la naturaleza, ingredientes obtenidos por biotecnología o disolventes no agrícolas.</h4>
						</li>
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable3.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Ingredientes sintéticos</p>
							<h4>Péptidos, moléculas activas sintéticas, filtros solares orgánicos.</h4>
						</li>
					</ul>
				</div>
			</PageText>

			<PageText backgroundImage={"linear-gradient(#eee8e2, #fff)"} >
				<h1 className="paddingBottom" style={{ width: "100%", color: "#BA0C2F" }}>
					Un nivel de requisitos previos y dos niveles de etiquetado para plantas
				</h1>
				<div
					className="fullSizeImage"
					style={{
						backgroundImage: `url("./images/pages/SourcingResponsable/sourcingResponsable5_es.png")`
					}}
				/>
			</PageText >

		</>
	)
}
