import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Microbiologie() {

	return (
		<>
			<PageTitle>微生物学</PageTitle>

			<PageVideo videoName={"itwLenogue"} style={{ paddingTop: '40px' }}>
				Delphine lenogue<br />微生物实验室负责人
			</PageVideo>

			<PageText style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				<p>
					我们的微生物专家团队致力于确保我们的产品在消费者的整个使用期间保持性状良好，同时保护他们的皮肤平衡。
					<br />
					<br />
					该部门由三个团队组成，分别参与产品开发过程的不同阶段。
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Microbiologie/microbiologie1.png"} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				<p className="paddingTopBottom">
					产品配方开发之前，一个专门从事研究工作的团队每年都会创建大约十个合作项目，旨在更好地了解皮肤和环境中的微生物。
				</p>
				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="paddingTopBottom">
						<p>
							我们最具创新性的研究工作（微生物学、生物学、植物化学、配方、临床评估）会在同行评审的科学期刊或国际会议上分享，
							也可能会申请专利。
						</p>
					</div>
				</div>
				<p className="paddingTopBottom">
					该团队还致力于开发防腐分子组合，以实现抑菌效果和良好皮肤耐受性之间的微妙平衡。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Microbiologie/microbiologie2.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p style={{ color: "white" }} >
					另外两个微生物学家团队致力于：
					<br /><br />
					• 评估已开发配方中防腐方案的有效性
					<br /><br />
					• 对工业环境、原料以及投放市场之前的成品进行微生物控制。
					<br /><br />
					安全和质量是我们所有活动的关键词。
				</p>
			</PageDisplayImage>
		</>
	)
}
