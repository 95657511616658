import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Prospective() {

	return (
		<>
			<PageTitle>PROSPECTIVA</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">​El Laboratorio de Prospectiva Texturas tiene varias misiones:</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective1.png"} imageSide="right">
				<h1 className="paddingTopBottom">
					INVESTIGACIÓN DE NUEVOS  EXCIPIENTES
				</h1>
				<p>
					•  Sustitución des excipientes habituales por <span className="bold">alternativas más eficaces, más verdes o más sostenibles.</span>
					<br /><br />
					•  Nuevos ingredientes que pueden permitir la <span className="bold">creación de texturas novedosas</span>.
					<br /><br />
					Cada elemento de su dossier es evaluado de manera rigurosa para garantizar que cumple los requerimientos <span className="bold">de nuestro Grupo en materia de ingredientes. </span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective2.png"} backgroundImage="none" backgroundColor="transparent">
				<h1 className="paddingTopBottom">
					PREPARACIÓN DEL DESARROLLO
				</h1>
				<p>
					Para Los proyectos más ambiciosos de la marca, el equipo Prospectiva <span className="bold">realiza cierto tipo de pruebas</span> que sirven de base de trabajo para los equipos de desarrollo. Aquel equipo comienza a estructurar los productos alrededor de los activos clave, en caso de que ya se conozcan en ese momento.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective3.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F">
				<h1 className="paddingTopBottom" style={{ color: "white" }} >
					CREACIÓN DE FÓRMULAS ORIGINALES
				</h1>
				<p style={{ color: "white" }} >
					Para estos proyectos no existen requerimientos especiales; esto para permitir una <span className="bold">mayor libertad creativa</span> y favorecer el desarrollo de conceptos totalmente innovadores que podrán <span className="bold">inspirar al marketing futuras ideas de proyectos</span>.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }}>VEAMOS LOS OTROS LABORATORIOS DE FORMULACIÓN</h2>
			</PageText>
		</>
	)
}
