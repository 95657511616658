import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function PoleReglementation() {

	return (
		<>
			<PageTitle>合规部</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Reglementation/reglementation1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					一般法规和合规性验证（RG）部门负责监控和确保原料、配方和成品符合法规要求。
					<br /><br />
					国际法规多种多样，可能会规范、禁止或限制使用某些成分以及某些声明。
					<br /><br />
					此团队的专家负责根据产品的销售区域和现行法规、制剂类型、消费者目标群体、应用区域以及所需声明来确保配方遵守相应法规。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imageSide="center" imgSrc={"/images/pages/Reglementation/reglementation2.png"} backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingTop" style={{ color: "white" }} >
					他们还负责检查所有的沟通要素（包装、网站、手册、社交媒体等），并确保证明数据符合预期要求。
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >探索我们的其他部门</h2>
			</PageText>
		</>
	)
}
