import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Ares() {

	return (
		<>
			<PageTitle>ARES</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Ares/ares1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					<span className="bold">ARES是“法规事务和安全性评估”（Affaires Réglementaires et Évaluation de la sécurité）的首字母缩写。</span>
					<br /><br />
					该部门确保我们的产品安全性，同时遵守产品销售的国家/地区的现行法规。
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} > 探索我们的不同部门</h2>
			</PageText>
		</>
	)
}
