import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import "../../../style.css";

export default function Formulation() {

	return (
		<>
			<PageTitle>配方</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">欢迎来到配方实验室！</span></span></span>
					<br />
					<span><span className="second">Agnès将向您讲述她在护肤品开发实验室的工作。
					</span></span>
					<br />
					<span><span className="third">您还将了解乳液的关键生产步骤，以及我们三个配方实验室的具体任务。</span></span>
				</p>
			</PageText>

			<PageVideo videoName={"itwLeFur"} style={{ marginBottom: '100px' }}>
				Agnès Le Fur<br />护肤配方实验室负责人
			</PageVideo>

			<PageVideo videoName={"demoEmulsion"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				如何制作乳液？
			</PageVideo>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }}> 探索我们的3个配方实验室 </h2>
			</PageText>
		</>
	)
}
