import { useState, useEffect } from "react";
import { gsap } from "gsap";
import "../../style.css";
import "./introStyle.css"
import { useLanguage } from '../../Contexts/LanguageContext.jsx';

export default function Opening({ onEnter, onEndTransition }) {

	const { currentLanguage, setCurrentLanguage } = useLanguage()
	const [entered, setEntered] = useState(false);
	const [endTransition, setEndTransition] = useState(false);

	//When clicked, transition to the Entrance menu
	useEffect(() => onEnter(entered), [entered])

	//At the end of the entrance animation, unmount component
	useEffect(() => {
		onEndTransition(endTransition);
	}, [endTransition])

	//Hover animations on circles
	const animation = () => {
		gsap.killTweensOf("#circle-one");
		gsap.killTweensOf("#circle-two");
		gsap.killTweensOf("#circle-three");
		gsap.killTweensOf("#circle-four");
		const dur = 0.3;

		gsap.to("#circle-one", { scale: 1.2, ease: "power1.inOut", delay: 0, duration: dur });
		gsap.to("#circle-one", { scale: 1, ease: "elastic.out", delay: dur, duration: 5 });

		gsap.to("#circle-two", { scale: 1.15, ease: "power1.inOut", delay: 0.1, duration: dur });
		gsap.to("#circle-two", { scale: 1, ease: "elastic.out", delay: dur + 0.1, duration: 5 });

		gsap.to("#circle-three", { scale: 1.15, ease: "power1.inOut", delay: 0.2, duration: dur });
		gsap.to("#circle-three", { scale: 1, ease: "elastic.out", delay: dur + 0.2, duration: 5 });

		gsap.to("#circle-four", { scale: 1.15, ease: "power1.inOut", delay: 0.3, duration: dur });
		gsap.to("#circle-four", { scale: 1, ease: "elastic.out", delay: dur + 0.3, duration: 5 });
	}

	//Click animation on circles
	const enter = () => {
		setEntered(true);
		gsap.killTweensOf("#circle-one");
		gsap.killTweensOf("#circle-two");
		gsap.killTweensOf("#three");
		gsap.killTweensOf("#four");
		gsap.to("#videoGreyColor", { opacity: "0", delay: 1, duration: 1.5 });
		gsap.to("#circle-one", { scale: 5, duration: 2 });
		gsap.to("#circle-two", { scale: 5, duration: 2 });
		gsap.to("#circle-three", { scale: 5, duration: 2 });
		gsap.to("#circle-four", { scale: 5, duration: 2, onComplete: () => setEndTransition(true) },);
	}

	return <>
		<section id="opening" className={entered ? "close" : ""} >
			<div id="circle-one" className="circle" onMouseEnter={() => animation()} />
			<div id="circle-two" className="circle" />
			<div id="circle-three" className="circle" />
			<div id="circle-four" className="circle" />

			<button
				className="icon"
				id="circle-center"
				onClick={enter}>
				<img src="/images/general/soundOn.png" />
			</button>

			<p id="circle-text">
				{currentLanguage == 'fr' && (
					"Cliquez sur l'icône son pour une expérience optimale"
				)}

				{currentLanguage == 'es' && (
					"Haz clic en el icono de sonido para una experiencia óptima"
				)}

				{currentLanguage == 'cn' && (
					"点击声音图标以获得最佳体验"
				)}

				{currentLanguage == 'en' && (
					"Click on the sound icon for an optimal experience"
				)}
			</p>
		</section>

		<div id="videoGreyColor" />
	</>
}
