import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageBanner from "../../../Components/Pages/PageBanner.jsx";
import "../../../style.css";

export default function MaquillageSolaires() {

	return (
		<>
			<PageTitle>彩妆和防晒产品</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">此实验室开发所有使用有色颜料的产品，以及所有涉及紫外线防护的产品。</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Maquillage/maquillage1.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" >
				<h1 className="paddingTopBottom">
					彩妆
					开发
				</h1>
				<p>
					我们的彩妆产品融合了即时化妆效果和护肤功效。它们的开发需要与护肤品一样严格，还涉及特定的色彩专家的专业知识，以便我们的化学家能够开发色调并验证其随着时间推移的稳定性。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Maquillage/maquillage2.png"} >
				<h1 className="paddingTop">
					防晒产品开发

				</h1>
				<p className="paddingTop">
					由于法规限制（在某些国家/地区，防晒产品可能被视为药品）和技术要求，防晒产品的配方特别复杂。
					<br /><br />
					需要进行数十项体外和临床试验来验证配方的防晒效果。
				</p>
			</PageDisplayImage>

			<PageBanner imgSrc={"/images/pages/Maquillage/maquillage3.png"} >
				在不影响消费者期望的感官体验的情况下实现高SPF值是一项真正的挑战，
				<br />
				我们的专家通过选择合适的防晒剂和乳化剂，出色地应对了这一挑战。
			</PageBanner>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >探索我们的其他配方实验室</h2>
			</PageText>
		</>
	)
}
