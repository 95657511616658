import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Microbiologie() {

	return (
		<>
			<PageTitle>MICROBIOLOGÍA</PageTitle>

			<PageVideo videoName={"itwLenogue"} style={{ paddingTop: '40px' }}>
				Delphine lenogue<br />RESPONSABLE DE LOS LABORATORIOS DE MICROBIOLOGÍA
			</PageVideo>

			<PageText style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				<p>
					Nuestros equipos de expertos microbiólogos trabajan diariamente para garantizar la <span className="bold">conservación de nuestros productos durante toda la duración de utilización</span> por parte de los consumidores, así como el mantenimiento del equilibrio de su piel.
					<br />
					<br />
					Este departamento está conformado por 3 equipos que intervienen en diferentes momentos del proceso de desarrollo.
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Microbiologie/microbiologie1.png"} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				<p className="paddingTopBottom">
					Previamente al desarrollo de las fórmulas, un equipo de investigación pone en marcha unas diez colaboraciones cada año; lo anterior con el <span className="bold">propósito de lograr un mejor conocimiento de los microorganismos de la piel, así como del entorno</span>.
				</p>
				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="paddingTopBottom">
						<p>
							Nuestras investigaciones más innovadoras (microbiología, biología, fitoquímica, formulación, evaluación clínica) son publicados en <span className="bold">revistas científicas</span> con comité de lectura o durante <span className="bold">congresos internacionales</span>.
							También pueden <span className="bold">solicitarse patentes</span> para algunos de ellos.
						</p>
					</div>
				</div>
				<p className="paddingTopBottom">
					Este equipo también se encarga de <span className="bold">elaborar combinaciones de moléculas conservantes, un sutil equilibrio entre eficacia antimicrobiana y tolerancia cutánea</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Microbiologie/microbiologie2.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p style={{ color: "white" }} >
					Los otros dos equipos de microbiólogos se encargan de:
					<br /><br />
					• <span className="bold">evaluar la eficacia de los sistemas de conservación</span> en las fórmulas desarrolladas.
					<br /><br />
					• <span className="bold">los controles microbiológicos del entorno industrial</span> de las <span className="bold">materias primas</span>, así como de los <span className="bold">productos acabados</span> antes de su comercialización.
					<br /><br />
					<span className="bold">La seguridad y la calidad son las palabras clave de todas nuestras actividades</span>.
				</p>
			</PageDisplayImage>
		</>
	)
}
