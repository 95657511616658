import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Conditionnement() {

	return (
		<>
			<PageTitle>包装：</PageTitle>

			<PageText style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingBottom" style={{ width: "100%", color: "#BA0C2F", textAlign: "left", textTransform: "uppercase" }}>
					产品包装是如何进行的？
				</h1>
				<p>
					超过200人： 包括包装工、搬运工、维修技术员、管理人员，他们分为3个班次（包括一个夜班），全天24小时工作。
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement1.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					包装过程实现了成品的组装
				</h1>
				<p className="paddingTop">
					使用来自生产流程的散装管、瓶、罐以及不同的包装物料。
					<br /><br />
					25条生产线专门用于生产各种类型的包装，如管状容器、样品、罐或瓶，容量从3毫升到400毫升不等；一条管状容器生产线只生产管状容器，一条乳液生产线只生产乳液。
					<br /><br />
					只有少数生产线能够生产两种类型的包装，但所有生产线都可以生产多种规格的包装。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement2.png"} >
				<h1 className="paddingTop">
					生产线自动化水平日益提高，并配备视觉控制系统。
				</h1>
				<p className="paddingTop" >
					操作员的主要任务是监督生产线的运行，为其供应原料，并根据未来顾客的使用需求对生产的产品进行检查。
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement3.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					由于需要生产的不同品目数量庞大……
				</h1>
				<p className="paddingTop">
					……接近3000种，因此产品之间的更换非常频繁。
					<br /><br />
					在一轮生产结束时，所有与面霜或乳液接触的工具部件都会被拆卸并清洗。
					<br /><br />
					此外，所有机器的设置都必须更改，方可生产下一款成品。因此，同一生产批次（更换批次）持续时间超过一天的情况非常罕见。
				</p>
			</PageDisplayImage>
		</>
	)
}
