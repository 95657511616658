import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function BiologieCellulaire() {

	return (
		<>
			<PageTitle>BIOLOGÍA <br />CELULAR</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Marie os explicará <span className="bold">de qué se encarga el laboratorio de biología celular</span>.</span></span>
					<br />
					<span><span className="second">¿Deseáis saber más acerca del método de preparación de las muestras de piel?</span></span>
				</p>
			</PageText>

			<PageText>
				<div
					style={{
						height: "30vh",
						width: "100%",
						borderRadius: "5px",
						backgroundImage: `url(${"/images/pages/BiologieCellulaire/biologieCellulaire1.png"})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						zIndex: 1
					}}
				/>
			</PageText>

			<PageVideo videoName={"itwDorr1"} style={{ paddingBottom: '100px' }}>
				Marie Dorr<br />JEFE DE PROYECTOS EVALUACIÓN IN VITRO
			</PageVideo>

			<PageVideo videoName={"demoExplant"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px' }}>
				MÉTODO DE PREPARACIÓN DE LAS MUESTRAS DE PIEL
			</PageVideo>
		</>
	)
}
