import { useState, useEffect } from "react";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
//import video from "../../../videoPaths.js"
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function ControleQualite() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	return (
		<>
			<PageTitle>Quality Control <br /> Laboratory</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span>
						<span className="first">Today, global quality standards are becoming more and more demanding, and we have to comply with various regulations worldwide. Our site is <span className="bold">inspected</span> by <span className="bold">authorities from each customer country</span>. Hence the importance of the quality department, which <span className="bold">guarantees the safety and conformity of our products</span>.</span>
					</span>
				</p>
			</PageText >

			<PageText>
				<h1 style={{ textTransform: "uppercase", width: "100%", color: "#BA0C2F", textAlign: "left" }}>
					The control laboratory is involved in all stages of the production process:
				</h1>
			</PageText>

			<PageDisplayImage isVideo={true} videoSrc={video["demoControleQualite1"]} videoSize="small" backgroundImage="none" imageSide="right">
				<p>
					… from raw materials and packaging, to manufactured creams and finished products.
					<br /><br />
					Each <b>product</b> reference has its own <b>specific control sheet</b> listing all the tests to be carried out, including organoleptic, physico-chemical, microbiological and usage tests.
					<br /><br />
					Products are <b>released</b> once all <b>tests defined by regulatory requirements and Clarins standards have been checked</b>.
					<br />
					But testing is only a small part of what goes into controlling product quality at Clarins.
				</p>
			</PageDisplayImage>

			<PageText style={{ paddingBottom: '100px' }}>
				<h1 className="paddingTop" style={{ width: "100%", color: "#BA0C2F", textTransform: "uppercase" }}>
					Quality is the work of an entire team on a daily basis:
				</h1>
			</PageText>

			<PageText backgroundColor={"#eee8e2"}>
				<div
					className="fullSizeImage"
					style={{
						backgroundImage: `url("./images/pages/ControleQualite/controleQualite1-EN.png")`
					}}
				/>
			</PageText >

			<PageVideo videoName={"demoControleQualite2"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} noControls>
			</PageVideo>
		</>
	)
}
