import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function PoleEnregistrements() {

	return (
		<>
			<PageTitle>REGISTROS <br />INTERNACIONALES Y DIP</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Enregistrements/enregistrements1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					El polo  Registros Internacionales y DIP <span className="bold">se ocupa de las diligencias administrativas</span> previas que se requieren para la comercialización de los productos cosméticos en los diferentes países en los que se distribuirán.
					<br /><br />
					De esta manera se encarga de <span className="bold">garantzar la adecuación reglamentaria de los DIP</span> (dossier información producto) de los productos cosméticos comercializados y de la <span className="bold">gestión de los registros sanitarios</span> de las materias primas y de los productos.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Enregistrements/enregistrements2.png"} style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingTopBottom">
					<span className="bold">El DIP es el ”documento de identidad”</span> que permite que el producto pueda ser comercializado.
					<br /><br />
					Se destina a las autoridades de control y debe contener la información siguiente proveniente de los diferentes servicios que intervienen en su creación (Investigación y Desarrollo, Operaciones, Piloto, Fabricación, Calidad, entre otros):
				</p>
				<div id="displayImageHighlight" className="marginTopBottom first">
					<div className="paddingTopBottom">
						<p>
							•  La identificación y la descripción del producto (fórmula, especificaciones, etiquetado, etc.).
							<br /><br />
							• El Informe de Evaluación de la Seguridad; Debe incluir los resultados de estabilidad, compatibilidad, seguridad microbiológica, inocuidad, reporte de cosmetovigilancia, etc.
							<br /><br />
							• La evidencia de la eficacia reivindicada.
							<br /><br />
							• La descripción del método de aplicación y una declaración de conformidad con las buenas prácticas de fabricación..
						</p>
					</div>
				</div>
				<p className="paddingTopBottom">
					<span className="bold">El polo garantiza la exhaustividad de los DIP</span> antes de la comercialización de los productos y procura que los DIP se mantengan al día durante todo el tiempo que los productos permanecerán en el mercado, incluso 10 años después de su salida del mercado.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >LOS OTROS POLOS</h2>
			</PageText>
		</>
	)
}
